import AOS from 'aos';

AOS.init({});

let samt = 0;
window.addEventListener('scroll', function() {
    samt <= 10 ? samt++ : AOS.refresh();
});

(() => {
    if(window.location.hostname.includes('dev')) {
        document.querySelectorAll('a[href]').forEach((el) => {
            el.href = el.href.replace('checkthis.cloud', 'devcheck.cloud');
        });
    }
})();

document.querySelector('.copyTime').innerHTML = (new Date()).getFullYear().toString();